var exports = {};

exports = function (str, sep) {
  if (typeof str !== "string") {
    throw new TypeError("Expected a string");
  }

  sep = typeof sep === "undefined" ? "_" : sep;
  return str.replace(/([a-z\d])([A-Z])/g, "$1" + sep + "$2").replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + sep + "$2").toLowerCase();
};

export default exports;